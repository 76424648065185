import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useSearchParams } from 'next/navigation';
import qs from 'qs';
import {
  useLocalizedPathname,
  useLocalizedRouter,
} from '@/shared/i18n/routing';

const parseSearchParams = (searchParams: URLSearchParams) => {
  return qs.parse(searchParams.toString(), {
    ignoreQueryPrefix: true,
  });
};

const getValueByName = (searchParams: URLSearchParams, name: string) => {
  const parsedSearchParams = parseSearchParams(searchParams);
  return parsedSearchParams[name];
};

const useQueryParam = <
  T extends ReturnType<typeof getValueByName> = ReturnType<
    typeof getValueByName
  >,
>(
  paramName: string
): [T, Dispatch<SetStateAction<T>>] => {
  const router = useLocalizedRouter();
  const pathname = useLocalizedPathname();
  const searchParams = useSearchParams();

  const [paramValue, setParamValue] = useState<T>(
    () => getValueByName(searchParams, paramName) as T
  );

  useEffect(() => {
    setParamValue(getValueByName(searchParams, paramName) as T);
  }, [searchParams, paramName]);

  const setParam = (value: SetStateAction<T>) => {
    setParamValue(value);

    const parsedSearchParams = parseSearchParams(searchParams);

    if (typeof value === 'function') {
      value = value(paramValue);
    }

    if (value === null || (Array.isArray(value) && value.length === 0)) {
      delete parsedSearchParams[paramName];
    } else {
      parsedSearchParams[paramName] = value;
    }

    const newSearchParams = qs.stringify(parsedSearchParams, {
      addQueryPrefix: true,
    });

    router.push(`${pathname}${newSearchParams}`);
  };

  return [paramValue, setParam];
};

export default useQueryParam;
