'use client';
import { FC } from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import { LazyImage } from '@/shared/ui/LazyImage/LazyImage';
import { DivProps } from 'react-html-props';
import clsx from 'clsx';
import { useLocale } from 'next-intl';
import { useGetGlobalOptions } from '@/shared/hooks/useGetGlobalOptions';

interface Props extends VariantProps<typeof cvaRoot>, DivProps {
  src?: string;
  alt?: string;
}

const cvaRoot = cva(
  ['Avatar-cvaRoot', 'overflow-hidden flex-shrink-0 rounded-[50%]'],
  {
    variants: {
      size: {
        128: 'w-[12.8rem] h-[12.8rem]',
        64: 'w-6.4 h-6.4 sm-max:w-4.8 sm-max:h-4.8',
        48: 'w-4.8 h-4.8',
      },
    },
    defaultVariants: {
      size: 128,
    },
  }
);

const defaultSize = 128;

const Avatar: FC<Props> = ({
  size = defaultSize,
  className,
  alt,
  src,
  ...props
}) => {
  const locale = useLocale();

  const { data: globalOptions } = useGetGlobalOptions({ lang: locale });

  const source = src || globalOptions?.settings_avatar_default?.url;

  return (
    <div className={clsx(cvaRoot({ size }), className)} {...props}>
      {source ? (
        <LazyImage
          src={source}
          width={size || defaultSize}
          height={size || defaultSize}
          alt={alt || ''}
          imageWrapClass={'w-full h-full'}
          className={'object-cover w-full h-full'}
        />
      ) : null}
    </div>
  );
};

export default Avatar;
