import { cva, VariantProps } from 'class-variance-authority';
import clsx from 'clsx';
import { CSSProperties, forwardRef, ReactNode } from 'react';

interface Props extends VariantProps<typeof cvaRoot> {
  children?: ReactNode;
  id?: string;
  className?: string;
  style?: CSSProperties;
  dangerouslySetInnerHTML?: { __html: string | TrustedHTML };
}

const Container = forwardRef<HTMLDivElement, Props>(
  (
    { children, id, className, style, size, gutters, dangerouslySetInnerHTML },
    ref
  ) => {
    return (
      <div
        ref={ref}
        id={id}
        className={clsx(cvaRoot({ size, gutters }), className)}
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
        style={style}>
        {children}
      </div>
    );
  }
);

const cvaRoot = cva(['Container-cvaRoot', 'w-full', 'mx-auto'], {
  variants: {
    size: {
      xl: 'max-w-[190rem]',
      lg: 'max-w-[140rem]',
      md: 'max-w-[128rem]',
      sm: 'max-w-[80rem] md-max:max-w-[74.8rem]',
    },
    gutters: {
      xl: 'px-4.8 xl-max:px-3.2 lg-max:px-2.4 sm-max:px-1.2',
      lg: 'px-3.6 md-max:px-1.2 sm-max:px-0.8 ',
      md: 'px-2.4 md-max:px-1.2',
      sm: 'px-1.6 md-max:px-1.2',
      xs: 'px-1.2',
    },
  },
});

Container.displayName = 'Container';

export default Container;
