import { useClickAway } from 'react-use';

export default function useClickOutside(
  ref: any,
  preventClickElements: (HTMLElement | undefined | null | string)[],
  cb: (e: Event) => void
) {
  useClickAway(
    ref,
    (e) => {
      if (
        preventClickElements.every((el) => {
          if (typeof el == 'string') {
            const elem = (e.target as HTMLElement).closest(el);
            return !elem?.contains(e.target as HTMLElement);
          }
          return !el?.contains(e.target as HTMLElement);
        })
      ) {
        cb(e);
      }
    },
    ['click', 'mousedown', 'touchstart']
  );
}
