'use client';
import {
  CSSProperties,
  forwardRef,
  ReactNode,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';
import { AnimatePresence, motion } from 'framer-motion';
import { Portal } from '@/shared/ui/Portal/Portal';
import useClickOutside from '@/shared/hooks/useClickOutside';
import mergeRefs from 'merge-refs';

export type PopoverProps = {
  children: ReactNode;
  render: ReactNode;
  variant?: 'hover' | 'click';
  placement?: Placement;
  className?: string;
  innerClassName?: string;
  styleButton?: CSSProperties;
  isOpen: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  closeOnClickOutside?: boolean;
  buttonClassName?: string;
  style?: CSSProperties;
  offset?: [number, number];
};

export interface PopoverRef {
  referenceElement: HTMLDivElement | null;
  popperElement: HTMLDivElement | null;
}

export const Popover = forwardRef<PopoverRef, PopoverProps>(
  (
    {
      render,
      children,
      styleButton,
      placement = 'bottom-end',
      buttonClassName,
      innerClassName,
      onOpen,
      onClose,
      isOpen,
      className,
      variant = 'click',
      closeOnClickOutside = true,
      style,
      offset = [0, 4],
    },
    ref
  ) => {
    const [referenceElement, setReferenceElement] =
      useState<HTMLDivElement | null>(null);

    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
      null
    );
    const refBtn = useRef<HTMLDivElement>(null);
    const popupRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(
      ref,
      () => ({
        referenceElement,
        popperElement,
      }),
      [referenceElement, popperElement]
    );

    const onEnter = variant == 'click' ? undefined : onOpen;
    const onLeave = variant == 'click' ? undefined : onClose;
    const onClick = variant == 'hover' ? undefined : isOpen ? onClose : onOpen;

    useClickOutside(refBtn, [popupRef.current], (e) => {
      if (isOpen && closeOnClickOutside) {
        e.stopPropagation();
        onClose?.();
      }
    });

    const { styles, attributes } = usePopper(referenceElement, popperElement, {
      modifiers: [
        { name: 'arrow' },
        {
          name: 'offset',
          options: { offset },
        },
      ],
      placement,
      strategy: 'fixed',
    });

    return (
      <>
        <div
          onMouseEnter={onEnter}
          onMouseLeave={onLeave}
          onClick={onClick}
          style={styleButton}
          className={clsx(buttonClassName, 'cursor-pointer')}
          ref={mergeRefs(setReferenceElement, refBtn) as any}>
          {children}
        </div>

        <AnimatePresence>
          {isOpen && (
            <Portal>
              <motion.div
                ref={setPopperElement}
                id={'tooltip'}
                className={clsx(className, 'z-[30]')}
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
                style={{ ...style, ...styles.popper }}
                onMouseLeave={onLeave}
                {...attributes.popper}>
                <div ref={popupRef} className={clsx(innerClassName)}>
                  {render}
                </div>
              </motion.div>
            </Portal>
          )}
        </AnimatePresence>
      </>
    );
  }
);

Popover.displayName = 'Popover';
