import { forwardRef } from 'react';
import { cva } from 'class-variance-authority';
import { Popover, PopoverProps, PopoverRef } from '../Popover/Popover';
import clsx from 'clsx';

interface Props extends PopoverProps {}

const DefaultPopover = forwardRef<PopoverRef, Props>(
  ({ children, innerClassName, ...props }, ref) => {
    return (
      <Popover
        ref={ref}
        innerClassName={clsx(cvaRoot(), innerClassName)}
        {...props}>
        {children}
      </Popover>
    );
  }
);

DefaultPopover.displayName = 'DefaultPopover';

const cvaRoot = cva([
  'DefaultPopover-cvaRoot',
  'relative',
  'min-w-[32rem]',
  'p-1.2 z-10',
  'rounded-[2.4rem] md-max:rounded-[1.6rem]',
  'bg-cGray1100 shadow-popover',
]);

export default DefaultPopover;
