import { IDropdownItem } from '@/entities/Dropdown/Dropdown';
import { ITag } from '@/widgets/AddImage/ui/ImageController';
import { create } from 'zustand';

export interface IAddImage {
  isSelect: boolean;
  id: string;
  tags: ITag[];
  comment: string;
  category: IDropdownItem | null;
  is18Category: boolean;
  file?: File;
  src?: string;
  width: number;
  height: number;
}

const getCommonValue = <T>(
  images: IAddImage[],
  key: keyof IAddImage,
  defaultValue: T
): T => {
  const selectedImages = images.filter((image) => image.isSelect);

  if (selectedImages.length === 0) {
    const firstValue = images[0]?.[key];

    if (!firstValue) return defaultValue;

    const allValuesMatch = images.every(
      (image) => JSON.stringify(image[key]) === JSON.stringify(firstValue)
    );

    return allValuesMatch ? (firstValue as T) : defaultValue;
  }

  const firstValue = selectedImages[0][key];
  const allValuesMatch = selectedImages.every(
    (image) => JSON.stringify(image[key]) === JSON.stringify(firstValue)
  );

  return allValuesMatch ? (firstValue as T) : defaultValue;
};

export interface IImageStore<T> {
  images: T[];
  selectImages: () => T[];
  comment: () => string;
  tags: () => ITag[];
  category: () => IDropdownItem | null;
  is18Category: () => boolean;
  updateImages: (val: T) => void;
  selectAll: () => void;
  unselectAll: () => void;
  updateImageById: (id: string, updates: Partial<T>) => void;
  updateSelectImages: (update: Partial<T>) => void;
  updateAllImages: (update: Partial<T>) => void;
  deleteImage: (id: string | number) => void;
  deleteImages: (id: (number | string)[]) => void;
  setImages: (id: T[]) => void;
}

export const createImageStore = <T extends IAddImage>() =>
  create<IImageStore<T>>((set, get) => ({
    images: [],
    selectImages: () => get().images.filter((el) => el.isSelect),
    comment: () => getCommonValue(get().images, 'comment', ''),
    tags: () => getCommonValue(get().images, 'tags', []),
    category: () => getCommonValue(get().images, 'category', null),
    is18Category: () => getCommonValue(get().images, 'is18Category', false),
    setImages: (images) => set({ images }),
    deleteImage: (id) =>
      set((state) => ({ images: state.images.filter((el) => el.id !== id) })),
    deleteImages: (ids) =>
      set((state) => ({
        images: state.images.filter((el) => !ids.includes(el.id)),
      })),
    updateImages: (val) => set((state) => ({ images: [val, ...state.images] })),
    selectAll: () =>
      set((state) => ({
        images: state.images.map((el) => ({ ...el, isSelect: true })),
      })),
    unselectAll: () =>
      set((state) => ({
        images: state.images.map((el) => ({ ...el, isSelect: false })),
      })),
    updateImageById: (id, updates) =>
      set((state) => ({
        images: state.images.map((image) =>
          image.id === id ? { ...image, ...updates } : image
        ),
      })),
    updateSelectImages: (update) =>
      set((state) => ({
        images: state.images.map((image) => {
          if (!image.isSelect) return image;
          return update ? { ...image, ...update } : image;
        }),
      })),
    updateAllImages: (update) =>
      set((state) => ({
        images: state.images.map((image) => {
          return update ? { ...image, ...update } : image;
        }),
      })),
  }));
