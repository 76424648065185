import { FC } from 'react';
import { cva } from 'class-variance-authority';
import { IDropdownItem } from '../Dropdown/Dropdown';
import clsx from 'clsx';
import { LocalizedLink } from '@/shared/i18n/routing';

interface Props {
  className?: string;
  innerClassName?: string;
  onClick?: () => void;
  item: IDropdownItem;
  active: boolean;
  href?: string;
  target?: string;
  rel?: string;
}

const DropdownLink: FC<Props> = ({
  className,
  innerClassName,
  active,
  href,
  target,
  rel,
  onClick,
  item,
}) => {
  return href ? (
    <LocalizedLink
      className={clsx(cvaDropdownItem({ active }), className)}
      href={href}
      target={target}
      rel={rel}
      onClick={onClick}>
      <div className={clsx(cvaDropdownItemInner(), innerClassName)}>
        {item.icon ? (
          <div className={cvaDropdownItemIcon()}>{item.icon}</div>
        ) : null}
        <div className={cvaDropdownItemTitle()}>{item.title}</div>
      </div>
    </LocalizedLink>
  ) : (
    <div
      className={clsx(cvaDropdownItem({ active }), className)}
      onClick={onClick}>
      <div className={clsx(cvaDropdownItemInner(), innerClassName)}>
        {item.icon ? (
          <div className={cvaDropdownItemIcon()}>{item.icon}</div>
        ) : null}
        <div className={cvaDropdownItemTitle()}>{item.title}</div>
      </div>
    </div>
  );
};

const cvaDropdownItem = cva(
  [
    'NavigationDropdown-cvaDropdownItem',
    'px-1.2 pb-1.3',
    'cursor-pointer',
    'group',
    'relative',
    'text-cWhite text-1.6-600',
    'before:absolute before:left-0 before:top-0 before:w-full before:h-full before:bg-cBaseBlack before:border-2 before:border-cPurple900 before:opacity-0 before:transition-opacity before:duration-300 before:rounded-button',
    'hover:before:opacity-100',
  ],
  {
    variants: {
      active: {
        true: 'active before:opacity-100',
        false: '',
      },
    },
  }
);

const cvaDropdownItemInner = cva([
  'NavigationDropdown-cvaDropdownItemInner',
  'flex items-center',
  'border-t border-cGray700 group-first:border-0',
  'transition-all duration-300',
  'pt-1.3',
  'group-hover:border-transparent',
  'z-10',
  'relative',
]);

const cvaDropdownItemIcon = cva([
  'NavigationDropdown-cvaDropdownItemIcon',
  'flex',
]);

const cvaDropdownItemTitle = cva([
  'NavigationDropdown-cvaDropdownItemTitle',
  'mx-0.8',
]);

export default DropdownLink;
