'use client';
import clsx from 'clsx';
import Image from 'next/image';
import { forwardRef, SyntheticEvent, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { cvaImage, cvaImageWrap } from './LazyImageStyles';

interface ILazyImageProps {
  fill?: boolean;
  src: string;
  alt: string;
  width?: number;
  height?: number;
  className?: string;
  priority?: boolean;
  loading?: 'lazy' | 'eager';
  delay?: number;
  quality?: number;
  onLoad?: (e: SyntheticEvent<HTMLImageElement, Event>) => void;
  ivVisible?: boolean;
  imageWrapClass?: string;
  imageRatio?: string;
}
export const LazyImage = forwardRef<any, ILazyImageProps>(
  (
    {
      quality = 90,
      alt,
      src,
      fill,
      width,
      height,
      className,
      onLoad,
      loading = 'lazy',
      priority = false,
      ivVisible = true,
      delay = 0,
      imageRatio,
      imageWrapClass,
    }: ILazyImageProps,
    ref
  ) => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const { ref: imageWrapRef, inView } = useInView({
      triggerOnce: true,
      threshold: [0.1, 0.1, 0.1, 0.1],
    });

    return (
      <div
        className={clsx(
          cvaImageWrap({ type: fill ? 'absolute' : 'relative' }),
          imageWrapClass
        )}
        ref={imageWrapRef}>
        <Image
          ref={ref}
          quality={quality}
          loading={loading}
          priority={priority}
          className={clsx(
            cvaImage({
              state: isLoaded && ivVisible && inView ? 'loaded' : 'default',
            }),
            className
          )}
          style={{ transitionDelay: `${delay}s`, aspectRatio: imageRatio }}
          src={src}
          width={width}
          height={height}
          fill={fill}
          alt={alt}
          onLoad={(e) => {
            setIsLoaded(true);
            onLoad?.(e);
          }}
        />
      </div>
    );
  }
);

LazyImage.displayName = 'LazyImage';
